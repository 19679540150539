import { getEnvVar } from 'utils/env';
import RewardCustomInfo from 'components/signup/custom-info/reward/rewardCustomInfo';
import { BarmerCustomSignupInfo } from 'components/signup/custom-info/barmer/barmerCustomInfo';
import { PartnerBarmer } from 'assets';

// TY-585 This product creates a gymondo subscription
export const GANZKOERPER_FREEBIE_12M_PRODUCT_ID = 29;
export const RUECKENFIT_FREEBIE_12M_PRODUCT_ID = 39;

export const GYM_12M_REWARD_PRODUCTS = [GANZKOERPER_FREEBIE_12M_PRODUCT_ID, RUECKENFIT_FREEBIE_12M_PRODUCT_ID];

export enum INFLUENCER_CHANNEL_URL {
    LEFLOID = 504,
}

export enum PROGRAM_NAME {
    GANZKOERPER = 'ganzkoerper',
    RUECKENFIT = 'rueckenfit',
    OLDRUECKENFIT = 'old-rueckenfit',
}

export enum PARTNER_KEYS {
    NO_PARTNER = '',
    HEK = 'hek',
    HKK = 'hkk',
    MACHTFIT = 'machtfit',
    BARMER = 'barmer',
    BAHNBKK = 'bahnbkk',
    BIGDIREKT = 'bigdirekt',
    VIACTIV = 'viactiv',
    SCHWENNINGER = 'schwenninger',
    DAK = 'dak',
    BKK24 = 'bkk24',
    GLOBAL = 'global',
    PRONOVA = 'pronova',
    IKKNORD = 'ikk-nord',
    IKKBB = 'ikk-bb',
    DEBEKA = 'debeka',
    BKKFREUDENBERG = 'bkk-freudenberg',
    HEIMAT = 'heimat',
    BMWBKK = 'bmwbkk',
    BKKVDN = 'bkkvdn',
    BKKTECHNOFORM = 'bkktechnoform',
    PROFIT = 'profit',
    KKH = 'kkh',
}

export interface Partner {
    KEY: PARTNER_KEYS;
    PRODUCT_ID: number;
    IS_FREEBIE: boolean;
    REQUIRE_COMPANY_EMAIL: boolean;
    REQUIRE_BIRTHDATE_ON_SIGNUP: boolean;
    HAS_INSURANCE: boolean;
    REQUIRE_INSURANCE_OR_VOUCHER?: boolean;
    HAS_REGULAR_DISCLAIMER: boolean;
    LOGO?: string;
    CUSTOM_DISCLAIMER_HTML?: string;
    HIDE_DEFAULT_DISCLAIMER?: boolean;
    USES_EXTERNAL_IDENTITY_PROVIDER?: boolean;
    DISCLAIMER_CONFIRMATION_TEXT?: string;
    CUSTOM_REGISTRATION_COMPONENT?: JSX.Element;
    SIGNUP_CTA_TEXT?: string;
    HAS_VOUCHER: boolean;
    EMAIL_REGEX: string;
    PURCHASE_INTERVAL: number;
}

export enum PROGRAM_STATUS {
    EXPIRED = 'EXPIRED',
    FINISHED = 'FINISHED',
    PURCHASED = 'PURCHASED',
    UNSET = 'UNSET',
    INVALID = 'INVALID',
}

interface Status {
    ONBOARDING: {
        FINISHED: string;
        NOT_FINISHED: string;
    };
    PLAN: {
        RUNNING: string;
    };
}

interface Config {
    PROGRAMS: {
        ID: number;
        COURSE_ID: string;
        NAME: string;
        TITLE: string;
        PRICE: string;
        TRAINER: {
            NAME: string;
            QUALIFICATION: string;
        };
        PARTNERS: Partner[];
    }[];
    STATUSES: Status;
    TIMELINE: {
        ACTIVE_POS: number;
        MIN_ALLOWED_INTERVAL: number;
        MAX_ALLOWED_INTERVAL: number;
        WEEKS_REMAINING: number;
    };
    ERROR_REASONS: Record<string, number>;
    ERROR_CODES: Record<string, string>;
}

export const PATHS = {
    ACCOUNT: '/account',
    FAQ: '/faq',
    HOMEPAGE: '/',
    IMPRINT: '/imprint',
    NOT_FOUND: '/not-found',
    PURCHASE_NOT_ALLOWED: '/purchase-not-allowed',
    ONBOARDING: '/onboarding',
    PARTICIPATION_CONFIRMATION: '/participation-confirmation',
    PAYMENT_CONFIRMATION: '/payment-confirmation',
    REGISTRATION: '/registration',
    REGISTRATION_PROGRAM: '/registration/:programName',
    PAYMENT: '/subscribe',
    TERMS: '/terms-and-conditions',
    TIMELINE: '/timeline/module-:moduleId',
};

const config: Config = {
    PROGRAMS: [
        {
            ID: Number(getEnvVar('PP_RUECKENFIT_ID')),
            COURSE_ID: 'KU-BE-8LQKAM',
            NAME: PROGRAM_NAME.RUECKENFIT,
            TITLE: 'Rücken fit & gesund (Kräftigung) – Onlinekurs mit Gymondo',
            PRICE: '99,00',
            TRAINER: {
                NAME: 'Patricio Escher',
                QUALIFICATION: 'Physiotherapeut & Präventionstrainer',
            },
            PARTNERS: [
                {
                    KEY: PARTNER_KEYS.NO_PARTNER,
                    PRODUCT_ID: RUECKENFIT_FREEBIE_12M_PRODUCT_ID,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    CUSTOM_DISCLAIMER_HTML: `
                    <p>Mit meiner Registrierung stimme ich den
                    <a href="https://prevention.gymondo.com/terms-and-conditions" target="_blank" class="link">AGB</a>
                    von Gymondo für Präventionskurse sowie den
                    <a href="https://www.gymondo.com/de/terms-and-conditions/" target="_blank" class="link">AGB</a>
                    zur Nutzung der Gymondo Plus Plattform zu.</p>`,
                    HIDE_DEFAULT_DISCLAIMER: true,
                    CUSTOM_REGISTRATION_COMPONENT:
                        RewardCustomInfo({ title: 'Präventionskurs Rücken fit & gesund' }) ?? undefined,
                    SIGNUP_CTA_TEXT: 'Ohne Abo, ohne Kündigung',
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0, // how soon a user can make a new purchase, in days, 0 - immediately
                },
                {
                    KEY: PARTNER_KEYS.HEK,
                    PRODUCT_ID: 40,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.HKK,
                    PRODUCT_ID: 41,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.MACHTFIT,
                    PRODUCT_ID: 42,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: true,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.PROFIT,
                    PRODUCT_ID: 63,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: true,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BARMER,
                    PRODUCT_ID: 43,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    REQUIRE_INSURANCE_OR_VOUCHER: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    LOGO: PartnerBarmer,
                    CUSTOM_REGISTRATION_COMPONENT: BarmerCustomSignupInfo({ programTitle: 'Rücken fit & gesund' }),
                    HAS_VOUCHER: true,
                    USES_EXTERNAL_IDENTITY_PROVIDER: true,
                    CUSTOM_DISCLAIMER_HTML: `
                    <p>Ich habe die <a href="https://prevention.gymondo.com/terms-and-conditions" target="_blank" class="link">AGB</a> von Gymondo für den Kurs Funktionale Ganzkörperkräftigung gelesen, verstanden und stimme mit dem Klick auf den Button „Jetzt Starten“ zu. Die <a href="https://www.gymondo.com/de/privacy" target="_blank" class="link">Datenschutzhinweise</a> von Gymondo habe ich zur Kenntnis genommen.</p>`,
                    HIDE_DEFAULT_DISCLAIMER: true,
                    DISCLAIMER_CONFIRMATION_TEXT:
                        'Ja, ich möchte per E-Mail über die Vorteile und Leistungen der Barmer informiert werden. Ich bin damit einverstanden, dass Gymondo zu diesem Zweck meine E-Mail-Adresse weitergibt. Die Einwilligung kann jederzeit widerrufen werden. Weitere Informationen dazu in den Datenschutzhinweisen von <a href="https://www.gymondo.com/de/privacy" target="_blank" class="link">Gymondo</a> und der <a href="https://www.barmer.de/consent/bestmoeglicher-schutz-fuer-ihre-daten-1272514" class="link">Barmer</a>.',
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BAHNBKK,
                    PRODUCT_ID: 44,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BIGDIREKT,
                    PRODUCT_ID: 45,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.VIACTIV,
                    PRODUCT_ID: 46,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.SCHWENNINGER,
                    PRODUCT_ID: 47,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.DAK,
                    PRODUCT_ID: 48,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: true,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: false,
                    CUSTOM_DISCLAIMER_HTML:
                        'Bitte verwende ausschließlich deine Firmen-E-Mail-Adresse. Diese ist wichtig und erforderlich, damit Gymondo die korrekten Konditionen abbilden kann, die für die Beschäftigten der DAK-Gesundheit gelten. Die Angabe deiner Krankenversichertennummer dient dem automatischen Ausfüllen der Teilnahmebescheinigung. Die Daten werden hierbei nicht an Dritte weitergegeben.',
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '@dak\\.',
                    PURCHASE_INTERVAL: 365, // days
                },
                {
                    KEY: PARTNER_KEYS.BKK24,
                    PRODUCT_ID: 49,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.GLOBAL,
                    PRODUCT_ID: 51,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.PRONOVA,
                    PRODUCT_ID: 52,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 365, // days
                },
                {
                    KEY: PARTNER_KEYS.IKKNORD,
                    PRODUCT_ID: 53,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.DEBEKA,
                    PRODUCT_ID: 54,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BKKFREUDENBERG,
                    PRODUCT_ID: 55,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.HEIMAT,
                    PRODUCT_ID: 56,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BMWBKK,
                    PRODUCT_ID: 57,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BKKVDN,
                    PRODUCT_ID: 59,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BKKTECHNOFORM,
                    PRODUCT_ID: 61,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.KKH,
                    PRODUCT_ID: 65,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    REQUIRE_INSURANCE_OR_VOUCHER: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: true,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.IKKBB,
                    PRODUCT_ID: 68,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: true,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
            ],
        },
        {
            ID: Number(getEnvVar('PP2_ID')),
            COURSE_ID: 'KU-BE-SLPFXF',
            NAME: PROGRAM_NAME.GANZKOERPER,
            TITLE: 'Funktionelle Ganzkörperkräftigung - Onlinetraining mit Gymondo',
            PRICE: '99,00',
            TRAINER: {
                NAME: 'Patricio Escher',
                QUALIFICATION: 'Rehabilitationscoach, Personal Trainer & EXOS Performance Specialist',
            },
            PARTNERS: [
                {
                    KEY: PARTNER_KEYS.NO_PARTNER,
                    PRODUCT_ID: GANZKOERPER_FREEBIE_12M_PRODUCT_ID,
                    IS_FREEBIE: false,
                    HAS_INSURANCE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    CUSTOM_DISCLAIMER_HTML: `
                    <p>Mit meiner Registrierung stimme ich den
                    <a href="https://prevention.gymondo.com/terms-and-conditions" target="_blank" class="link">AGB</a>
                    von Gymondo für Präventionskurse sowie den
                    <a href="https://www.gymondo.com/de/terms-and-conditions/" target="_blank" class="link">AGB</a>
                    zur Nutzung der Gymondo Plus Plattform zu.</p>`,
                    HIDE_DEFAULT_DISCLAIMER: true,
                    CUSTOM_REGISTRATION_COMPONENT:
                        RewardCustomInfo({ title: 'Präventionskurs Funktionelle Ganzkörperkräftigung' }) ?? undefined,
                    SIGNUP_CTA_TEXT: 'Ohne Abo, ohne Kündigung',
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.HEK,
                    PRODUCT_ID: 13,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.HKK,
                    PRODUCT_ID: 14,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.MACHTFIT,
                    PRODUCT_ID: 15,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: true,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.PROFIT,
                    PRODUCT_ID: 64,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: true,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BARMER,
                    PRODUCT_ID: 16,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    REQUIRE_INSURANCE_OR_VOUCHER: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    LOGO: PartnerBarmer,
                    CUSTOM_REGISTRATION_COMPONENT: BarmerCustomSignupInfo({
                        programTitle: 'Funktionelle Ganzkörperkräftigung',
                    }),
                    HAS_VOUCHER: true,
                    USES_EXTERNAL_IDENTITY_PROVIDER: true,
                    CUSTOM_DISCLAIMER_HTML: `
                    <p>Ich habe die <a href="https://prevention.gymondo.com/terms-and-conditions" target="_blank" class="link">AGB</a> von Gymondo für den Kurs Funktionale Ganzkörperkräftigung gelesen, verstanden und stimme mit dem Klick auf den Button „Jetzt Starten“ zu. Die <a href="https://www.gymondo.com/de/privacy" target="_blank" class="link">Datenschutzhinweise</a> von Gymondo habe ich zur Kenntnis genommen.</p>`,
                    HIDE_DEFAULT_DISCLAIMER: true,
                    DISCLAIMER_CONFIRMATION_TEXT:
                        'Ja, ich möchte per E-Mail über die Vorteile und Leistungen der Barmer informiert werden. Ich bin damit einverstanden, dass Gymondo zu diesem Zweck meine E-Mail-Adresse weitergibt. Die Einwilligung kann jederzeit widerrufen werden. Weitere Informationen dazu in den Datenschutzhinweisen von <a href="https://www.gymondo.com/de/privacy" target="_blank" class="link">Gymondo</a> und der <a href="https://www.barmer.de/consent/bestmoeglicher-schutz-fuer-ihre-daten-1272514" class="link">Barmer</a>.',
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BAHNBKK,
                    PRODUCT_ID: 17,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BIGDIREKT,
                    PRODUCT_ID: 18,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.VIACTIV,
                    PRODUCT_ID: 19,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.SCHWENNINGER,
                    PRODUCT_ID: 20,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.DAK,
                    PRODUCT_ID: 21,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: true,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: false,
                    CUSTOM_DISCLAIMER_HTML:
                        'Bitte verwende ausschließlich deine Firmen-E-Mail-Adresse. Diese ist wichtig und erforderlich, damit Gymondo die korrekten Konditionen abbilden kann, die für die Beschäftigten der DAK-Gesundheit gelten. Die Angabe deiner Krankenversichertennummer dient dem automatischen Ausfüllen der Teilnahmebescheinigung. Die Daten werden hierbei nicht an Dritte weitergegeben.',
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '@dak\\.',
                    PURCHASE_INTERVAL: 365, // days
                },
                {
                    KEY: PARTNER_KEYS.BKK24,
                    PRODUCT_ID: 22,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.GLOBAL,
                    PRODUCT_ID: 26,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.PRONOVA,
                    PRODUCT_ID: 28,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 365, // days
                },
                {
                    KEY: PARTNER_KEYS.IKKNORD,
                    PRODUCT_ID: 32,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.DEBEKA,
                    PRODUCT_ID: 34,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BKKFREUDENBERG,
                    PRODUCT_ID: 36,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.HEIMAT,
                    PRODUCT_ID: 38,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BMWBKK,
                    PRODUCT_ID: 58,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BKKVDN,
                    PRODUCT_ID: 60,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BKKTECHNOFORM,
                    PRODUCT_ID: 62,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    // Never to be purchased, solely here to support old purchases.
                    KEY: PARTNER_KEYS.NO_PARTNER,
                    PRODUCT_ID: 12,
                    IS_FREEBIE: false,
                    HAS_INSURANCE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.KKH,
                    PRODUCT_ID: 66,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    REQUIRE_INSURANCE_OR_VOUCHER: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: true,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.IKKBB,
                    PRODUCT_ID: 69,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: true,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
            ],
        },
        {
            // Never to be purchased, solely here to support old purchases.
            ID: Number(getEnvVar('PP1_ID')),
            COURSE_ID: '20210216-1285792',
            NAME: PROGRAM_NAME.OLDRUECKENFIT,
            TITLE: 'Gymondo Rücken fit & gesund (Kräftigung) - Online Programm',
            PRICE: '79,00',
            TRAINER: {
                NAME: 'Patricio Escher',
                QUALIFICATION: 'Physiotherapeut & Präventionstrainer',
            },
            PARTNERS: [
                {
                    KEY: PARTNER_KEYS.NO_PARTNER,
                    PRODUCT_ID: 1,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0, // how soon a user can make a new purchase, in days, 0 - immediately
                },
                {
                    KEY: PARTNER_KEYS.HEK,
                    PRODUCT_ID: 2,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.HKK,
                    PRODUCT_ID: 3,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.MACHTFIT,
                    PRODUCT_ID: 4,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: true,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BARMER,
                    PRODUCT_ID: 5,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    REQUIRE_INSURANCE_OR_VOUCHER: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    LOGO: PartnerBarmer,
                    CUSTOM_REGISTRATION_COMPONENT: BarmerCustomSignupInfo({ programTitle: 'Rücken fit & gesund' }),
                    HAS_VOUCHER: true,
                    USES_EXTERNAL_IDENTITY_PROVIDER: true,
                    CUSTOM_DISCLAIMER_HTML: `
                    <p>Ich habe die <a href="https://prevention.gymondo.com/terms-and-conditions" target="_blank" class="link">AGB</a> von Gymondo für den Kurs Funktionale Ganzkörperkräftigung gelesen, verstanden und stimme mit dem Klick auf den Button „Jetzt Starten“ zu. Die <a href="https://www.gymondo.com/de/privacy" target="_blank" class="link">Datenschutzhinweise</a> von Gymondo habe ich zur Kenntnis genommen.</p>`,
                    HIDE_DEFAULT_DISCLAIMER: true,
                    DISCLAIMER_CONFIRMATION_TEXT:
                        'Ja, ich möchte per E-Mail über die Vorteile und Leistungen der Barmer informiert werden. Ich bin damit einverstanden, dass Gymondo zu diesem Zweck meine E-Mail-Adresse weitergibt. Die Einwilligung kann jederzeit widerrufen werden. Weitere Informationen dazu in den Datenschutzhinweisen von <a href="https://www.gymondo.com/de/privacy" target="_blank" class="link">Gymondo</a> und der <a href="https://www.barmer.de/consent/bestmoeglicher-schutz-fuer-ihre-daten-1272514" class="link">Barmer</a>.',
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BAHNBKK,
                    PRODUCT_ID: 6,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BIGDIREKT,
                    PRODUCT_ID: 7,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.VIACTIV,
                    PRODUCT_ID: 8,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.SCHWENNINGER,
                    PRODUCT_ID: 9,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.DAK,
                    PRODUCT_ID: 10,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: true,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: false,
                    CUSTOM_DISCLAIMER_HTML:
                        'Bitte verwende ausschließlich deine Firmen-E-Mail-Adresse. Diese ist wichtig und erforderlich, damit Gymondo die korrekten Konditionen abbilden kann, die für die Beschäftigten der DAK-Gesundheit gelten. Die Angabe deiner Krankenversichertennummer dient dem automatischen Ausfüllen der Teilnahmebescheinigung. Die Daten werden hierbei nicht an Dritte weitergegeben.',
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '@dak\\.',
                    PURCHASE_INTERVAL: 365, // days
                },
                {
                    KEY: PARTNER_KEYS.BKK24,
                    PRODUCT_ID: 11,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.GLOBAL,
                    PRODUCT_ID: 25,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: false,
                    HAS_REGULAR_DISCLAIMER: false,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.PRONOVA,
                    PRODUCT_ID: 27,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 365, // days
                },
                {
                    KEY: PARTNER_KEYS.IKKNORD,
                    PRODUCT_ID: 31,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.DEBEKA,
                    PRODUCT_ID: 33,
                    IS_FREEBIE: false,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.BKKFREUDENBERG,
                    PRODUCT_ID: 35,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
                {
                    KEY: PARTNER_KEYS.HEIMAT,
                    PRODUCT_ID: 37,
                    IS_FREEBIE: true,
                    REQUIRE_COMPANY_EMAIL: false,
                    REQUIRE_BIRTHDATE_ON_SIGNUP: false,
                    HAS_INSURANCE: true,
                    HAS_REGULAR_DISCLAIMER: true,
                    HAS_VOUCHER: false,
                    EMAIL_REGEX: '',
                    PURCHASE_INTERVAL: 0,
                },
            ],
        },
    ],
    TIMELINE: {
        ACTIVE_POS: 0,
        MIN_ALLOWED_INTERVAL: 0,
        MAX_ALLOWED_INTERVAL: 6,
        WEEKS_REMAINING: 12,
    },
    ERROR_REASONS: {
        VOUCHER_ACTIVE_SUBSCRIPTION: 1000,
        USER_NOT_EXISTING: 1101,
        USER_ALREADY_EXISTING: 1102,
        MAX_INCORRECT_SIGNINS: 1111,
        VOUCHER_NOT_EXISTING: 3000,
        INVALID_VOUCHER: 3002,
        INCOMPATIBLE_VOUCHER: 3007,
        ACTIVE_SUBSCRIPTION: 3400,
        HMAC_VERIFICATION_FAILED: 3402,
        UNKNOWN_PAYMENT_RESPONSE: 3403,
        UNAUTHORISED_PAYMENT: 3404,
        PAYMENT_ALREADY_PROCESSED: 3406,
        PROGRAM_ALREADY_BOUGHT: 3408,
        NO_RIGHTS: 9901,
        INCORRECT_PASSWORD: 9916,
        INVALID_CHANNEL: 4000,
    },
    ERROR_CODES: {
        INVALID_PASSWORD_EXCEPTION: 'InvalidPasswordException',
        DUPLICATE_USER_EXCEPTION: 'DuplicateUserException',
        USER_NOT_FOUND_EXCEPTION: 'UserNotFoundException',
        NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
        LIMIT_EXCEEDED_EXCEPTION: 'LimitExceededException',
        INVALID_PARTNER_USER_EXCEPTION: 'InvalidPartnerUserException',
        ALREADY_REGISTERED_PARTNER_USER_EXCEPTION: 'AlreadyRegisteredPartnerUserException',
    },
    STATUSES: {
        ONBOARDING: {
            FINISHED: 'FINISHED',
            NOT_FINISHED: 'NOT_FINISHED',
        },
        PLAN: {
            RUNNING: 'RUNNING',
        },
    },
};

export default config;
